<template>
  <div class="main">
    <div class="page-bg"></div>

    <div class="animation-wrapper">
      <div class="particle particle-1"></div>
      <div class="particle particle-2"></div>
      <div class="particle particle-3"></div>
      <div class="particle particle-4"></div>
    </div>
    <v-img
      lazy-src="https://picsum.photos/id/11/10/6"
      max-height="400"
      width="100%"
      cover
      src="https://firebasestorage.googleapis.com/v0/b/haulee-1b003.appspot.com/o/btcBg.jpeg?alt=media&token=89e802e6-37b5-4fdc-8560-6b4f23aa938f"
    >
      <v-row class="fill-height ma-0" align="center" justify="center">
        <v-col cols="12" md="6">
          <h1 class="text-h3 font-weight-bold mb-4 textWhite">Contact</h1>
          <v-card-actions>
            <v-icon color="#d4b506">mdi-home</v-icon>
            <v-btn text color="#fff" @click="navigate('dashboard')">
              Home
            </v-btn>
            <div class="textWhite">/ Contact</div>
          </v-card-actions>
        </v-col>
        <v-col cols="12" md="6"> </v-col>
      </v-row>
    </v-img>

    <v-container>
      <v-row justify="center" align="center">
        <v-col cols="12" md="6" order-sm="1">
          <v-card class="pa-5" shaped dark>
            <div class="textWhite text-h3">Contact.</div>
            <div class="textWhite text-h3">Get in touch.</div>
            <div class="textWhite">
              <small>Leave us a message </small>
            </div>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="fullName"
                  filled
                  label="Full Name"
                  clearable
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="email"
                  filled
                  label="Email Address"
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
            <v-textarea
              filled
              auto-grow
              label="Message"
              rows="4"
              row-height="30"
              shaped
              v-model="message"
            ></v-textarea>
            <v-btn
              @click="contactUs()"
              large
              color="#d4b506"
              light
              class="ma-2"
            >
              Send Massage
            </v-btn>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" order-sm="2">
          <v-img
            lazy-src="https://picsum.photos/id/11/10/6"
            height="700"
            width="100%"
            contain
            src="https://firebasestorage.googleapis.com/v0/b/haulee-1b003.appspot.com/o/btcBg.jpeg?alt=media&token=89e802e6-37b5-4fdc-8560-6b4f23aa938f"
          >
          </v-img>
        </v-col>
      </v-row>
      <div class="pt-8 mb-10">
        <div class="textWhite text-h3">Quick.</div>
        <div class="textWhite text-h3">Support.</div>
        <div class="textWhite mb-7">
          <small>You can get all information </small>
        </div>
        <v-row>
          <v-col cols="12" md="4">
            <v-card class="pa-7" width="350" height="200" color="#d4b506">
              <div><v-icon class="mb-3">mdi-phone</v-icon></div>
              <div class="text-h5">Call Us</div>
              <div class="text-h7">+44 LXGMiners.</div>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="pa-7" width="350" height="200" color="#d4b506">
              <div><v-icon class="mb-3">mdi-email</v-icon></div>
              <div class="text-h5">Mail Us</div>
              <div class="text-h7">support@lxgminers.co</div>
              <div class="text-h7">admin@lxgminers.co</div>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="pa-7" width="350" height="200" color="#d4b506">
              <div><v-icon class="mb-3">mdi-email</v-icon></div>
              <div class="text-h5">Visit Us</div>
              <div class="text-h7">
                4th Central Library, Surrey St, Sheffield City Centre, Sheffield
                S1 1XZ, United Kingdom
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <v-footer color="black" class="footer">
      <v-row justify="center" no-gutters class="pb-7">
        <v-col class="py-4 text-center white--text" cols="12">
          <div class="text-center">
            <h1 class="white--text">
              <v-icon large color="#d4b506">mdi-bitcoin</v-icon>
              LXGMiners
            </h1>
          </div>
        </v-col>
        <v-btn
          v-for="link in links"
          :key="link"
          color="white"
          text
          rounded
          class="my-2"
        >
          {{ link }}
        </v-btn>
        <v-col class="py-4 white--text" cols="12">
          © {{ new Date().getFullYear() }} —
          <strong
            ><span class="goldColor">LXGMINERS INC.</span> All rights
            reserved</strong
          >
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>
       
    <script>
export default {
  components: {},
  data: () => ({
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
    links: ["Home", "Privacy & Policy", "Terms & Conditions"],
    message: "",
    email: "",
    fullName: "",
  }),
  methods: {
    contactUs() {
      if (this.fullName == "" || this.email == "" || this.message == "") {
        this.$store.commit("setSnackbar", true);
        this.$store.commit("setServerMessage", "Fill All The Required Field");
        return;
      }
      let payload = {
        fullName: this.fullName,
        email: this.email,
        message: this.message,
      };
      this.$store.dispatch("ContactUs", payload);
      this.message = "";
      this.fullName = "";
      this.email = "";
    },
  },
};
</script>
      
      <style lang="scss" scoped>
.footer {
  border-top: 1px solid #d4b506 !important;
}
.card {
  min-height: 600px;
  background-color: rgb(5, 5, 5, 0.8) !important;
  padding: 55px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.main {
  flex: 1;
  background-color: black;
  height: 100vh;
}
.section-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 55vh;
}
.section-2 {
  background-color: rgb(5, 5, 5, 0.8) !important;
  margin: 5em;
}
.goldColor {
  color: #d4b506 !important;
}
.textWhite {
  color: white;
}
/* Config */
$color-bg: #1e1e1e;
$color-particle: #fff;
$spacing: 2560px;
$time-1: 60s;
$time-2: 120s;
$time-3: 180s;
$time-4: 200s;

/* mixins */
@function particles($max) {
  $val: 0px 0px $color-particle;
  @for $i from 1 through $max {
    $val: #{$val}, random($spacing) + px random($spacing) + px $color-particle;
  }
  @return $val;
}

@mixin particles($max) {
  box-shadow: particles($max);
  border-radius: 50%;
}

/* Styles */
html,
body {
  height: 100%;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  overflow: hidden;
}

.page-bg,
.animation-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.page-bg {
  background: $color-bg;
  background-blend-mode: screen;
  background-size: cover;
  filter: grayscale(100%);
  z-index: -1;
}

.particle,
.particle:after {
  background: transparent;
}

.particle:after {
  position: absolute;
  content: "";
  top: $spacing;
}

.particle-1 {
  animation: animParticle $time-1 linear infinite;
  @include particles(1000);
  height: 2px;
  width: 2px;
}

.particle-1:after {
  @include particles(900);
  height: 2px;
  width: 2px;
}

.particle-2 {
  animation: animParticle $time-2 linear infinite;
  @include particles(800);
  height: 2px;
  width: 2px;
}

.particle-2:after {
  @include particles(500);
  height: 3px;
  width: 3px;
}

.particle-3:after {
  @include particles(1100);
  height: 3px;
  width: 3px;
  border-radius: 50%;
}

.particle-4 {
  animation: animParticle $time-4 linear infinite;
  @include particles(1200);
  height: 1px;
  width: 1px;
}

.particle-4:after {
  @include particles(700);
  height: 1px;
  width: 1px;
}

@keyframes animParticle {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY($spacing * -1);
  }
}

.page-wrapper {
  text-align: center;
  color: #fff;
  z-index: 2;
}

h4 {
  color: #fff;
  font-family: Monoton;
  -webkit-animation: neon1 1.5s ease-in-out infinite alternate;
  -moz-animation: neon1 1.5s ease-in-out infinite alternate;
  animation: neon1 1.5s ease-in-out infinite alternate;
}

h4:hover {
  color: #ff1177;
  -webkit-animation: none;
  -moz-animation: none;
  animation: none;
}

/*glow for webkit*/

@-webkit-keyframes neon1 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff1177,
      0 0 70px #ff1177, 0 0 80px #ff1177, 0 0 100px #ff1177, 0 0 150px #ff1177;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff1177,
      0 0 35px #ff1177, 0 0 40px #ff1177, 0 0 50px #ff1177, 0 0 75px #ff1177;
  }
}

/*glow for mozilla*/

@-moz-keyframes neon1 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff1177,
      0 0 70px #ff1177, 0 0 80px #ff1177, 0 0 100px #ff1177, 0 0 150px #ff1177;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff1177,
      0 0 35px #ff1177, 0 0 40px #ff1177, 0 0 50px #ff1177, 0 0 75px #ff1177;
  }
}

/*glow*/

@keyframes neon1 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff1177,
      0 0 70px #ff1177, 0 0 80px #ff1177, 0 0 100px #ff1177, 0 0 150px #ff1177;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff1177,
      0 0 35px #ff1177, 0 0 40px #ff1177, 0 0 50px #ff1177, 0 0 75px #ff1177;
  }
}
</style>
      